import Segmenter from './helpers/Segmenter'

export default class SourceTextarea extends React.PureComponent
  constructor: (props) ->
    super(props)

    @highlightsRef = React.createRef()

    @onChange = @onChange.bind(this)
    @onScroll = @onScroll.bind(this)

  componentDidMount: ->
    @props.sourceRef.current.focus({ preventScroll: true })

  onChange: (e) ->
    sentences = Segmenter.sentences(e.target.value, @props.languageCode)
    @props.onChange(sentences)

  onScroll: (e) ->
    @highlightsRef.current.scrollTop = e.target.scrollTop

  render: ->
    classes = "textarea #{@props.fontSizeClass}"

    # Avoid redisplaying the previous textarea content when using the browser's back button
    # because it is out of sync with the state
    autoComplete = 'off'

    <>
      <TextareaAutosize className={classes}
                        ref={@props.sourceRef}
                        dir={@props.direction}
                        placeholder={@props.i18n.placeholder}
                        autoComplete={autoComplete}
                        onChange={@onChange}
                        onScroll={@onScroll}
                        value={@props.sources.join('')} />
      <div className={"highlights #{classes}"}
           ref={@highlightsRef}
           dir={@props.direction}>
        {@renderSentences()}
      </div>
    </>

  renderSentences: ->
    @props.sources.map((sentence, index) =>
      if sentence.trim().length
        classes = 'sentence'
        classes += ' active' if @props.activeSentenceIndex == index

        <span key="#{index}-#{sentence}"
              className={classes}>
          {sentence}
        </span>
      else
        sentence
    )

import GlobeIcon from '../icons/GlobeIcon'

export default class LocaleSelector extends React.PureComponent

  constructor: (props) ->
    super(props)

    @onChange = @onChange.bind(this)

  onChange:(e) ->
    path = window.location.pathname

    localeInPath = new RegExp("^\/#{_.escapeRegExp(@props.currentLocale)}") # only at start of path, like /^\/en/
    newPath      = path.replace(localeInPath, "/#{e.target.value}")

    window.location.href = newPath

  render: ->
    options = @props.locales.map((locale) =>
      <option value={locale.code} key={locale.code} >
        {locale.name}
      </option>
    )

    <div className="locale-switcher">
      <GlobeIcon />
      <select className="form-select form-select-sm w-auto"
              value={@props.currentLocale}
              onChange={@onChange} >
        {options}
      </select>
    </div>

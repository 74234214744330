export default class StreamingManagement

  constructor: (stateData, responseData, responseCompletion) ->
    ###
     StateData/ResponseData have the same structure and contains:
       * id (requestId)
       * sources
       * targets
    ###
    @state      = stateData
    @response   = responseData
    @completion = responseCompletion # 'partial' / 'sentence' / 'complete'

  bestTargets: ->
    if @response.id && (@isLastRequest() || @isBiggerVersion(@response.id, @state.id))
      if @completion == 'sentence' || @completion == 'complete'
        targets = @response.targets
      else
        if @state.targets.length && _.last(@state.targets).startsWith(_.last(@response.targets))
          targets = @state.targets
        else
          targets = @response.targets
    else
      targets = @state.targets

    targets

  isLastRequest: ->
    @isSameMajorVersion(@state.id, @response.id) && @completion == 'complete'

  isSameMajorVersion: (version1, version2) ->
    version1.split('.')[0] == version2.split('.')[0]

  isBiggerVersion: (version1, version2) ->
    splitted1 = version1.split('.')
    splitted2 = version2.split('.')

    firstNumber1 = parseInt(splitted1[0])
    firstNumber2 = parseInt(splitted2[0])
    lastNumber1  = parseInt(splitted1[1])
    lastNumber2  = parseInt(splitted2[1])

    firstNumber1 > firstNumber2 || (firstNumber1 == firstNumber2 && lastNumber1 > lastNumber2)

###

DRAFT OF BETTER METHOD?

###

# latestRequest = @isSameMajorVersion(data.requestId, @requestId) && data.completion == 'complete'

# if latestRequest
#   newTargets = data.targets
#   # After that, ignore everything before this request!
# else
#   newTargets = []

#   for i in [0...@state.sources.length]
#     stateSource = @state.sources[i] || ''
#     dataSource  = data.sources[i]   || ''

#     if stateSource == dataSource
#       stateTarget = @state.targets[i] || ''
#       dataTarget  = data.targets[i]   || ''

#       if stateTarget.length <= dataTarget.length && dataTarget.startsWith(stateTarget)
#         newTargets.push(dataTarget)
#       else if stateTarget.length > dataTarget.length && stateTarget.startsWith(dataTarget)
#         newTargets.push(stateTarget)
#       else
#         newTargets.push(dataTarget)
#         break
#     else if dataSource.length == 0 # data has less sentences that current state
#       if @state.targets[i]
#         newTargets.push(@state.targets[i])
#       else
#         break # sources don't match and no more
#     else
#       break

# newTargets = newTargets.filter((target) -> target.length > 0)

export default class FilesPanel extends React.PureComponent
  render: ->
    <div className="col">
      <div className="drop-target">
        { @renderInstructions() }
        { @renderTemporaryAlert() }
      </div>
    </div>

  renderInstructions: ->
    <div className="instructions">
      <i className="fa-regular fa-file"></i>
      <p>
        {@props.i18n.instructions.dropYourFile}
      </p>
    </div>

  renderTemporaryAlert: ->
    <div className="alert" role="alert">
      <p>
        {@props.i18n.alert.notAvailable}
      </p>

      <button className="btn btn-secondary btn-sm"
              onClick={@props.openModal.bind(this, 'files')}>
        {@props.i18n.alert.buttonLabel}
      </button>
    </div>

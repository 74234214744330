export default class Alternatives extends React.PureComponent
  constructor: (props) ->
    super(props)

  handleKeydown: (alternativeTargets, e) ->
    e.preventDefault() if e.code in ['ArrowUp', 'ArrowDown', 'Space', 'Enter']

    switch e.code
      when 'ArrowUp'        then @focusPrev(e.target)
      when 'ArrowDown'      then @focusNext(e.target)
      when 'Space', 'Enter' then @props.selectAlternative(alternativeTargets)

  focusPrev: (element) ->
    element.previousSibling.focus() if element.previousSibling

  focusNext: (element) ->
    element.nextSibling.focus() if element.nextSibling

  render: ->
    classes = "target-alternatives"
    classes += " visible" if @props.alternatives.length != 0

    <div className={classes}>
      <hr/>
      <h4>
        {@props.i18n.alternativesTitle}
      </h4>
      <ul dir={@props.direction}>
        {@renderListItems()}
      </ul>
    </div>

  renderListItems: ->
    @props.alternatives.map((alternativeTargets, index) =>
      @renderListItem(alternativeTargets, index)
    )

  renderListItem: (alternativeTargets, index) ->
    <li key={index}
        tabIndex="0"
        onKeyDown={@handleKeydown.bind(this, alternativeTargets)}
        onClick={@props.selectAlternative.bind(this, alternativeTargets)}>
      { alternativeTargets.join('').replace(/\s+/g, ' ') }
    </li>

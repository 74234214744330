export default class LanguageSwitchButton extends React.PureComponent

  constructor: (props) ->
    super(props)

    @onClick = @onClick.bind(this)

  onClick: (e) ->
    if !@props.disabled
      @props.onClick()

  render: ->
    classes = 'switch-languages'
    classes += ' disabled' if @props.disabled
      
    <div className={classes}
         onClick={@onClick}>
      <i className="fas fa-right-left"></i>
    </div>

export default class WaitingListForm extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      email: ''
      step:  'edit' # ['edit', 'adding', 'invalid', 'added']

    @updateEmail           = @updateEmail.bind(this)
    @submitEmail           = @submitEmail.bind(this)
    @triggerInputKeyDown   = @triggerInputKeyDown.bind(this)
    @updateWaitingListFlag = @updateWaitingListFlag.bind(this)

  componentDidUpdate: (prevProps) ->
    if prevProps.origin != @props.origin
      @setState(email: '')

      alreadyAdded = [
        @props.origin == 'limit'  && @props.waitingListLimit,
        @props.origin == 'api'    && @props.waitingListApi,
        @props.origin == 'files'  && @props.waitingListFiles,
        @props.origin == 'engine' && @props.waitingListEngine
      ]

      if alreadyAdded.includes(true)
        @setState(step: 'added')
      else
        @setState(step: 'edit')

  updateEmail: (e) ->
    @setState(
      email: e.target.value
      step:  'edit'
    )

  triggerInputKeyDown: (e) ->
    if e.code == 'Enter'
      @submitEmail()

  submitEmail: (e) ->
    if @state.step == 'edit'
      @setState(step: 'adding', =>
        params =
          email:   @state.email
          origin:  @props.origin  || 'none'
          choices: @props.choices || []
          locale:  @props.currentLocale

        http.post("/waiting_list.json", params, (data) =>
          if data.added
            @setState(
              step: 'added'
            , @updateWaitingListFlag)
          else
            @setState(step: 'invalid')
        )
      )

  updateWaitingListFlag: (e) ->
    @props.updateWaitingListFlag(@props.origin)

  render: ->
    <div className="email-form">
      { @renderErrorMessage() if @state.step == 'invalid' }
      { @renderEmailField() }
      { @renderSubmitButton() }
      { @renderFootNote() }
    </div>

  renderErrorMessage: ->
    <p className="invalid-email">
      {@props.i18n.invalidEmail}
    </p>

  renderEmailField: ->
    if !@props.currentUser
      classes =  'form-control'
      classes += ' invalid' if @state.step == 'invalid'

      <input className={classes}
             id="#{@props.formId}-email"
             type="email"
             name="email"
             placeholder={@props.i18n.emailPlaceholder}
             autoComplete="email"
             value={@state.email}
             onChange={@updateEmail}
             onKeyDown={@triggerInputKeyDown}
             disabled={['added', 'adding'].includes(@state.step)} />

  renderSubmitButton: ->
    classes  = if @state.step == 'added' then 'btn btn-success' else 'btn btn-primary'

    disabled = @state.step != 'edit' || (@state.email == '' && !@props.currentUser)

    if @props.currentUser
      i18n = @props.i18n.submitButton.user
    else
      i18n = @props.i18n.submitButton.visitor

    buttonText = switch
      when @state.step == 'added'  then i18n.successfullyAdded
      when @state.step == 'adding' then i18n.adding
      else                              i18n.subscribe

    <button type="submit"
            className={classes}
            onClick={@submitEmail}
            disabled={disabled}>
      {buttonText}
    </button>

  renderFootNote: ->
    if @state.step == 'added'
      <p className="contact-us">
        {@props.i18n.reachOut}
        {' '}
        <a target="_blank" href="mailto:contact@neurooo.com">
          contact@neurooo.com
        </a>
      </p>
    else
      <p className="no-spam-note">
        {@props.i18n.noSpam}
      </p>

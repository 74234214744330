# Get and Set caret position in a contenteditable="true" component
# cf. https://stackoverflow.com/a/62700928/1243212
export default class CaretPosition

  @get: (element) ->
    selection = window.getSelection()
    charCount = -1
    node      = ''

    if selection.focusNode
      if @_isChildOf(selection.focusNode, element)
        node      = selection.focusNode
        charCount = selection.focusOffset

        while node
          break if node == element

          if node.previousSibling
            node       = node.previousSibling
            charCount += node.textContent.length
          else
            node = node.parentNode

            break if node == null

    charCount

  @set: (element, chars) ->
    if chars >= 0
      selection = window.getSelection()
      range = @_createRange(element, { count: chars })

      if range
        range.collapse(false)
        selection.removeAllRanges()
        selection.addRange(range)

  @_createRange: (node, chars, range) ->
    if !range
      range = document.createRange()
      range.selectNode(node)
      range.setStart(node, 0)

    if chars.count == 0
      range.setEnd(node, chars.count)
    else if node && chars.count > 0
      if node.nodeType == Node.TEXT_NODE
        if node.textContent.length < chars.count
          chars.count -= node.textContent.length
        else
          range.setEnd(node, chars.count)
          chars.count = 0
      else
        lp = 0

        while lp < node.childNodes.length
          range = @_createRange(node.childNodes[lp], chars, range)
          break if chars.count == 0
          lp++

    range

  @_isChildOf: (node, element) ->
    while node != null
      return true if node == element
      node = node.parentNode

    return false

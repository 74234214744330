// Get and Set caret position in a contenteditable="true" component
// cf. https://stackoverflow.com/a/59599363/1243212
export default class CaretPosition {
  static get(container) {
    let start = 0
    let end = 0

    const selection = window.getSelection()
    for (let i = 0, len = selection.rangeCount; i < len; i++) {
      const range = selection.getRangeAt(i)
      if (range.intersectsNode(container)) {
        const startNode = range.startContainer
        this.searchNode(container, container, node => {
          if (startNode === node) {
            start += this.getAbsoluteOffset(node, range.startOffset)
            return true
          }

          const dataLength =
            node.nodeType === Node.TEXT_NODE ? node.data.length : 0

          start += dataLength
          end += dataLength

          return false
        })

        const endNode = range.endContainer
        this.searchNode(container, startNode, node => {
          if (endNode === node) {
            end += this.getAbsoluteOffset(node, range.endOffset)
            return true
          }

          const dataLength =
            node.nodeType === Node.TEXT_NODE ? node.data.length : 0

          end += dataLength

          return false
        })

        break
      }
    }

    return [start, end]
  }

  static set(node, start, end) {
    const range = this.createRange(node, start, end)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }

  /** return true if node found */
  static searchNode(container, startNode, predicate, excludeSibling) {
    if (predicate(startNode)) {
      return true
    }

    for (let i = 0, len = startNode.childNodes.length; i < len; i++) {
      if (this.searchNode(startNode, startNode.childNodes[i], predicate, true)) {
        return true
      }
    }

    if (!excludeSibling) {
      let parentNode = startNode
      while (parentNode && parentNode !== container) {
        let nextSibling = parentNode.nextSibling
        while (nextSibling) {
          if (this.searchNode(container, nextSibling, predicate, true)) {
            return true
          }
          nextSibling = nextSibling.nextSibling
        }
        parentNode = parentNode.parentNode
      }
    }

    return false
  }

  static createRange(container, start, end) {
    let startNode
    this.searchNode(container, container, node => {
      if (node.nodeType === Node.TEXT_NODE) {
        const dataLength = node.data.length
        if (start <= dataLength) {
          startNode = node
          return true
        }
        start -= dataLength
        end -= dataLength
        return false
      }
    })

    let endNode
    if (startNode) {
      this.searchNode(container, startNode, node => {
        if (node.nodeType === Node.TEXT_NODE) {
          const dataLength = node.data.length
          if (end <= dataLength) {
            endNode = node
            return true
          }
          end -= dataLength
          return false
        }
      })
    }

    const range = document.createRange()

    if (startNode) {
      if (start < startNode.data.length) {
        range.setStart(startNode, start)
      } else {
        range.setStartAfter(startNode)
      }
    } else {
      if (start === 0) {
        range.setStart(container, 0)
      } else {
        range.setStartAfter(container)
      }
    }

    if (endNode) {
      if (end < endNode.data.length) {
        range.setEnd(endNode, end)
      } else {
        range.setEndAfter(endNode)
      }
    } else {
      if (end === 0) {
        range.setEnd(container, 0)
      } else {
        range.setEndAfter(container)
      }
    }

    return range
  }

  // static hasChild(container, node) {
  //   while (node) {
  //     if (node === container) {
  //       return true
  //     }
  //     node = node.parentNode
  //   }

  //   return false
  // }

  static getAbsoluteOffset(container, offset) {
    if (container.nodeType === Node.TEXT_NODE) {
      return offset
    }

    let absoluteOffset = 0
    for (
      let i = 0, len = Math.min(container.childNodes.length, offset);
      i < len;
      i++
    ) {
      const childNode = container.childNodes[i]
      this.searchNode(childNode, childNode, node => {
        if (node.nodeType === Node.TEXT_NODE) {
          absoluteOffset += node.data.length
        }
        return false
      })
    }

    return absoluteOffset
  }

  // static getInnerText(container) {
  //   const buffer = []
  //   this.searchNode(container, container, node => {
  //     if (node.nodeType === Node.TEXT_NODE) {
  //       buffer.push(node.data)
  //     }
  //     return false
  //   })
  //   return buffer.join("")
  // }
}

import * as cldrSegmentation from 'cldr-segmentation'

export default class Segmenter

  @words: (text, language) ->
    suppressions = cldrSegmentation.suppressions[language] # https://github.com/camertron/cldr-segmentation.js/#sentence-segmentation
    cldrSegmentation.wordSplit(text, suppressions)

  @sentences = (text, language) ->
    suppressions = cldrSegmentation.suppressions[language]
    sentences    = cldrSegmentation.sentenceSplit(text, suppressions)

    @splitSpaces(sentences)

  # Don't mix sentences with starting/ending spaces in same strings
  # split the extra spacings into own sentences (that will be ignored on the backend).
  #
  # It's very useful for the backend cache, so it can ignores spacing and concentrate on pure text.
  @splitSpaces = (sentences) ->
    newSentences = []

    for sentence in sentences
      results = sentence.match(/^(\s*)([\s\S]*?)(\s*)$/)

      newSentences.push(results[1]) if results[1] != '' # before spaces
      newSentences.push(results[2]) if results[2] != '' # text itself
      newSentences.push(results[3]) if results[3] != '' # after spaces

    ###
    # Commented because it only works with SourceA! (contentEditable)
    # And it doesn't seem to be necessary for sourceB to visually keep consistent lines?
    ###
    # # Remove all last empty sentences
    # if newSentences.length
    #   for i in [newSentences.length-1..0] # from last index to first
    #     if newSentences[i].trim() == ''
    #       newSentences.pop()
    #     else
    #       break

    newSentences


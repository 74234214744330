# Get and Set caret position in a contenteditable="true" component
# cf. https://stackoverflow.com/a/59599363/1243212
export default class CaretPosition
  @get: (container) ->
    start     = 0
    end       = 0
    selection = window.getSelection()

    for i in [0...selection.rangeCount] # '...' = max value not included
      range = selection.getRangeAt(i)

      if range.intersectsNode(container)
        startNode = range.startContainer

        @searchNode(container, container, (node) =>
          if startNode == node
            start += @getAbsoluteOffset(node, range.startOffset)
            return true

          if node.nodeType == Node.TEXT_NODE
            dataLength = node.data.length
          else
            dataLength = 0

          start += dataLength
          end   += dataLength

          return false
        )

        endNode = range.endContainer

        @searchNode(container, startNode, (node) =>
          if endNode == node
            end += @getAbsoluteOffset(node, range.endOffset)
            return true

          if node.nodeType == Node.TEXT_NODE
            dataLength = node.data.length
          else
            dataLength = 0

          end += dataLength

          return false
        )

        break

    return [start, end]

  @set: (node, start, end) ->
    range = @createRange(node, start, end)
    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

  # return true if node found
  @searchNode: (container, startNode, predicate, excludeSibling) ->
    return true if predicate(startNode)

    for i in [0...startNode.childNodes.length] # '...' = max value not included
      return true if @searchNode(startNode, startNode.childNodes[i], predicate, true)

    if !excludeSibling
      parentNode = startNode

      while parentNode && parentNode != container
        nextSibling = parentNode.nextSibling

        while nextSibling
          return true if @searchNode(container, nextSibling, predicate, true)
          nextSibling = nextSibling.nextSibling

        parentNode = parentNode.parentNode

    return false

  @createRange: (container, start, end) ->
    startNode = undefined

    @searchNode(container, container, (node) =>
      if node.nodeType == Node.TEXT_NODE
        dataLength = node.data.length

        if dataLength >= start
          startNode = node
          return true

        start -= dataLength
        end   -= dataLength
        return false
    )

    endNode = undefined

    if startNode
      @searchNode(container, startNode, (node) =>
        if node.nodeType == Node.TEXT_NODE
          dataLength = node.data.length

          if dataLength >= end
            endNode = node
            return true

          end -= dataLength
          return false
      )

    range = document.createRange()

    if startNode
      if startNode.data.length > start
        range.setStart(startNode, start)
      else
        range.setStartAfter(startNode)
    else
      if start == 0
        range.setStart(container, 0)
      else
        range.setStartAfter(container)

    if endNode
      if endNode.data.length > end
        range.setEnd(endNode, end)
      else
        range.setEndAfter(endNode)
    else
      if end == 0
        range.setEnd(container, 0)
      else
        range.setEndAfter(container)

    range

  # static hasChild(container, node) {
  #   while (node) {
  #     if (node == container) {
  #       return true
  #     }
  #     node = node.parentNode
  #   }

  #   return false
  # }

  @getAbsoluteOffset: (container, offset) ->
    return offset if container.nodeType == Node.TEXT_NODE

    absoluteOffset = 0

    for i in [0...Math.min(container.childNodes.length, offset)] # '...' = max value not included
      childNode = container.childNodes[i]

      @searchNode(childNode, childNode, (node) =>
        if node.nodeType == Node.TEXT_NODE
          absoluteOffset += node.data.length

        return false
      )

    absoluteOffset

  # static getInnerText(container) {
  #   const buffer = []
  #   this.searchNode(container, container, node => {
  #     if (node.nodeType == Node.TEXT_NODE) {
  #       buffer.push(node.data)
  #     }
  #     return false
  #   })
  #   return buffer.join("")
  # }


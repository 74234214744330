export default class RobotIcon extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    <svg className="neuron-robot" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="shape">
        <path className="Vector" fillRule="evenodd" clipRule="evenodd" d="M16.552 4.61328C6.28707 4.61328 5.91797 9.40935 5.91797 15.3256V16.8135C5.91797 22.7297 8.8652 27.5258 16.552 27.5258C24.2388 27.5258 27.186 22.7297 27.186 16.8135V15.3256C27.186 9.40935 26.8169 4.61328 16.552 4.61328ZM16.552 9.07677C10.3488 9.07677 10.3488 11.8745 10.3488 15.3256V16.8135C10.3488 20.2646 11.4433 23.0623 16.552 23.0623C21.6606 23.0623 22.7552 20.2646 22.7552 16.8135V15.3256C22.7552 11.8745 22.7552 9.07677 16.552 9.07677Z"/>
        <path className="Vector_2" d="M13.8895 17.1112C14.8684 17.1112 15.6619 16.1787 15.6619 15.0283C15.6619 13.8779 14.8684 12.9453 13.8895 12.9453C12.9107 12.9453 12.1172 13.8779 12.1172 15.0283C12.1172 16.1787 12.9107 17.1112 13.8895 17.1112Z"/>
        <path className="Vector_3" d="M19.2098 17.1112C20.1887 17.1112 20.9822 16.1787 20.9822 15.0283C20.9822 13.8779 20.1887 12.9453 19.2098 12.9453C18.231 12.9453 17.4375 13.8779 17.4375 15.0283C17.4375 16.1787 18.231 17.1112 19.2098 17.1112Z"/>
        <path className="Rectangle 17" d="M13.4062 2C13.4062 0.895431 14.3017 0 15.4062 0H17.5938C18.6983 0 19.5938 0.895431 19.5938 2V6.1875H13.4062V2Z"/>
        <path className="Rectangle 18" d="M31 11.3437C32.1046 11.3437 33 12.2392 33 13.3438L33 17.5938C33 18.6983 32.1046 19.5937 31 19.5937L29.3906 19.5937L29.3906 11.3437L31 11.3437Z"/>
        <path className="Rectangle 19" d="M2 11.3437C0.895431 11.3437 3.91405e-08 12.2392 8.74228e-08 13.3438L2.73196e-07 17.5938C3.21478e-07 18.6983 0.895431 19.5937 2 19.5937L3.60938 19.5937L3.60938 11.3437L2 11.3437Z"/>
      </g>
    </svg>

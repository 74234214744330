import NeuroLogo       from './logo/NeuroLogo'
import WaitingListForm from './WaitingListForm'

export default class Modal extends React.PureComponent
  constructor: (props) ->
    super(props)

  componentDidMount: ->
    modal = document.getElementById('modal-neuro')
    modal.addEventListener('shown.bs.modal', =>
      $('input#modal-email').trigger('focus')
    )

  render: ->
    classes =  'modal'
    classes += ' fade' if @props.environment != 'test'

    <div className={classes} id="modal-neuro" tabIndex="-1" aria-labelledby="modal-neuro-label" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          { @renderBody() }
        </div>
      </div>
    </div>

  renderBody: ->
    <div className="modal-body">
      <h5 className="sr-only" id="modal-neuro-label">
        {@props.i18n.stayInformed}
      </h5>

      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={@props.i18n.closeLabel}></button>

      <div className="logo-container">
        <NeuroLogo />
      </div>

      { @renderCustomText() }
      { @renderCommonText() }

      { @renderForm() }
    </div>

  renderCommonText: ->
    <p dangerouslySetInnerHTML={{__html: @props.i18n.earlyAccess}}>
    </p>

  renderCustomText: ->
    if @props.i18n.customTexts[@props.origin]
      <p className="custom-text">
        {@props.i18n.customTexts[@props.origin]}
      </p>

  renderForm: ->
    <WaitingListForm formId="modal"
                     origin={@props.origin}
                     currentUser={@props.currentUser}
                     waitingListLimit={@props.waitingListLimit}
                     waitingListApi={@props.waitingListApi}
                     waitingListFiles={@props.waitingListFiles}
                     waitingListEngine={@props.waitingListEngine}
                     updateWaitingListFlag={@props.updateWaitingListFlag}
                     currentLocale={@props.currentLocale}
                     i18n={@props.i18n.waitingListForm} />

import onClickOutside from 'react-onclickoutside'
import NeuronIcon     from './icons/NeuronIcon'

class LanguageDropdown extends React.PureComponent
  constructor: (props) ->
    super(props)

    @state =
      open:   false
      filter: ''

    # References used to put focus on elements
    @filterRef    = React.createRef()
    @languagesRef = React.createRef()

    # Bound methods
    @toggleDropdown        = @toggleDropdown.bind(this)
    @updateFilter          = @updateFilter.bind(this)
    @clearFilter           = @clearFilter.bind(this)
    @triggerFilterHotkey   = @triggerFilterHotkey.bind(this)
    @triggerLanguageHotkey = @triggerLanguageHotkey.bind(this)

  handleClickOutside: (e) ->
    @closeDropdown() if @state.open

  closeDropdown: ->
    @setState(
      open:   false
      filter: ''
    , =>
      @props.focusSource()
      @props.onClose() if @props.onClose
    )

  openDropdown: ->
    @setState(
      open: true
    , =>
      @props.onOpen() if @props.onOpen
    )

  toggleDropdown: (e) ->
    if @state.open
      @closeDropdown()
    else
      @openDropdown()

  updateFilter: (e) ->
    @setState(filter: e.target.value)

  clearFilter: (e) ->
    @setState(filter: '')

  triggerFilterHotkey: (e) ->
    switch e.code
      when 'Escape'    then @closeDropdown()
      when 'ArrowDown' then @languageLis()[0].focus(); e.preventDefault();
      when 'Enter'     then (@languageLis()[0].click(); e.preventDefault();) if @languageLis().length > 0

  # Using the keyboard to navigate/select languages
  triggerLanguageHotkey: (code, e) ->
    switch e.code
      when 'ArrowUp'        then @focusPrev(e.target); e.preventDefault();
      when 'ArrowDown'      then @focusNext(e.target); e.preventDefault();
      when 'Escape'         then @closeDropdown();
      when 'Space', 'Enter' then @selectLanguage(code);

  focusFilter: ->
    @filterRef.current.focus()
    @languagesRef.current.scrollTop = 0

  languageLis: ->
    Array.from(
      @languagesRef.current.querySelectorAll('li.language')
    )

  focusNext: (element) ->
    languageLis  = @languageLis()
    currentIndex = languageLis.findIndex((l) => l == element)

    if languageLis.length > currentIndex + 1
      languageLis[currentIndex + 1].focus()

  focusPrev: (element) ->
    languageLis  = @languageLis()
    currentIndex = languageLis.findIndex((l) => l == element)

    if currentIndex == 0
      @focusFilter()
    else
      languageLis[currentIndex - 1].focus()

  selectLanguage: (code) ->
    @closeDropdown()
    @props.onSelectLanguage(code)

  filteredTopLanguageCodes: ->
    codes = @props.topCodes

    if @props.autoDetectOption
      codes = [''].concat(codes)              # Add auto-detect
      codes = codes.slice(0, @props.maxCodes) # For symmetry with target language

    @filterCodes(codes)

  filteredOtherLanguageCodes: (excludedCodes) ->
    allCodes = Object.keys(@props.languagePerCode)
    codes    = _.difference(allCodes, excludedCodes)

    @filterCodes(codes)

  # To compare without accentuation and special chars (ç)
  # cf. https://stackoverflow.com/a/37511463/1243212
  normalize: (text) ->
    text.toLowerCase().trim().normalize("NFD").replace(/\p{Diacritic}/gu, '')

  filterCodes: (codes) ->
    filter = @normalize(@state.filter)

    codes = codes.filter((code) =>
      languageName = @normalize(@languageName(code))
      languageName.includes(filter)
    )

    _.sortBy(codes, (code) =>
      if code == '' # Detect Language should be first and not sorted
        '0'
      else
        @languageName(code)
    )

  languageName: (code) ->
    @props.i18n.languages[code] || @props.i18n.detectLanguage

  render: ->
    classes = "language-dropdown"
    classes += ' opened' if @state.open

    <div className={classes}>
      {@renderTitle()}
      {@renderDropdownMenu()}
    </div>

  renderTitle: ->
    btnClasses = 'btn btn-link btn-title'
    btnClasses += ' opened' if @state.open

    iconClasses = 'fas'
    iconClasses += ' fa-angle-up'   if @state.open
    iconClasses += ' fa-angle-down' if !@state.open

    <button type="button"
            className={btnClasses}
            onClick={@toggleDropdown}>
      <NeuronIcon />
      {@renderTitleText()}
      <i className="#{iconClasses}"></i>
    </button>

  renderTitleText: ->
    currentLanguageName = @languageName(@props.languageCode)

    if @state.open
      @props.placeholder
    else if @props.detection
      detectedLabel = @props.i18n.detected
      [
        currentLanguageName,
        <small key={"detected"}>({detectedLabel})</small> if @props.languageCode != ''
      ]
    else
      currentLanguageName

  renderDropdownMenu: ->
    if @state.open
      topLanguageCodes   = @filteredTopLanguageCodes()
      otherLanguageCodes = @filteredOtherLanguageCodes(topLanguageCodes)
      totalCodesCount    = topLanguageCodes.length + otherLanguageCodes.length

      <div className="language-dropdown-menu">
        <div className="filter">
          <input ref={@filterRef}
                 autoFocus="autofocus"
                 type="text"
                 placeholder={@props.i18n.searchPlaceholder}
                 value={@state.filter}
                 onChange={@updateFilter}
                 onKeyDown={@triggerFilterHotkey} />
          <i className="fas fa-search"></i>
          {@renderClearFilter()}
        </div>
        {@renderNoLanguages() if totalCodesCount == 0}
        <div className="languages" ref={@languagesRef}>
          { @renderLanguages('top', topLanguageCodes,   totalCodesCount) }
          { @renderLanguages('all', otherLanguageCodes, totalCodesCount) }
        </div>
      </div>

  renderClearFilter: ->
    if @state.filter.length
      <button type="button"
              className="btn-close btn-clear-filter"
              onClick={@clearFilter}>
      </button>

  renderNoLanguages: ->
    <div className="not-found">
      {@props.i18n.notFound} <mark>{@state.filter.trim()}</mark>
    </div>

  renderLanguages: (listType, languageCodes, totalCodesCount) ->
    title = @props.i18n["#{listType}LanguagesTitle"]

    if languageCodes.length
      <>
        <h3>{title}</h3>
        <ul className={"#{listType}-languages"}>
          {
            languageCodes.map((languageCode) =>
              @renderLanguage(languageCode, totalCodesCount)
            )
          }
        </ul>
      </>

  renderLanguage: (languageCode, totalCodesCount) ->
    isSelected = languageCode == @props.languageCode
    isHovered  = totalCodesCount == 1 # if only 1 code, it should be hovered (enter and it selects it!)

    languageName = @languageName(languageCode)

    classes = "language language-#{languageCode}"
    classes += ' selected' if isSelected
    classes += ' hovered'  if isHovered

    <li key={languageCode}
        tabIndex="0"
        className={classes}
        onClick={@selectLanguage.bind(this, languageCode)}
        onKeyDown={@triggerLanguageHotkey.bind(this, languageCode)}>
      {@renderLanguageName(languageName)}
      {@renderSelectedIcon() if isSelected}
    </li>

  renderLanguageName: (name) ->
    if @state.filter.trim() == ''
      name
    else
      # Construct highlight even if accentuation doesn't match
      regex  = new RegExp("#{_.escapeRegExp(@normalize(@state.filter))}")
      groups = @normalize(name).match(regex)
      index  = groups.index     # position of matched group
      length = groups[0].length # length of matched group

      part1 = name.substring(0, index)
      part2 = "<mark>" + name.substring(index, index + length) + "</mark>"
      part3 = name.substring(index + length, name.length)

      <span dangerouslySetInnerHTML={{ __html: "#{part1}#{part2}#{part3}" }}></span>

  renderSelectedIcon: ->
    <i className="fas fa-check"></i>

export default onClickOutside(LanguageDropdown)

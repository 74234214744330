/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

global.React     = require('react')
global.Pusher    = require('pusher-js')
global._         = require('lodash')
global.humps     = require('humps')
global.Bootstrap = require('bootstrap')

// We need to require this component at global level
// because SSR requires a specific dist (see comment server_rendering.js)
global.TextareaAutosize = require('react-textarea-autosize').default

global.http = require('../libraries/Http').default // https://stackoverflow.com/a/43249395/1243212 (cf. last paragraph)

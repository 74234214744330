import * as React from "react"
import CaretPosition  from './helpers/CaretPosition'
import CaretPosition2 from './helpers/CaretPosition2'

export default class ContentEditable extends React.PureComponent
  constructor: (props) ->
    super(props)

    @caretStart  = 0
    @caretEnd    = 0
    @LastKeyDead = false # Last key is "Dead" (`, ^ or any dead character)
                         # Fix for MacOS that can't preventDefault for Dead keys: https://stackoverflow.com/q/61255621/1243212

    @manageSpecialChars = @manageSpecialChars.bind(this)
    @updateValue        = @updateValue.bind(this)

  componentDidUpdate: ->
    #CaretPosition.set(@props.sourceRef.current, @caretStart)
    CaretPosition2.set(@props.sourceRef.current, @caretStart, @caretEnd)

  manageSpecialChars: (event) ->
    @LastKeyDead = false

    if event.key == 'Enter'                   # cf. https://github.com/lovasoa/react-contenteditable/issues/182#issuecomment-673678979
      document.execCommand('insertLineBreak') # Insert new line visually and don't update state
      event.preventDefault()
    else if event.key == 'Dead'
      @LastKeyDead = true
      event.preventDefault()

  updateValue: (event) ->
    if @props.onChange
      #@caretStart = CaretPosition.get(@props.sourceRef.current)
      [@caretStart, @caretEnd] = CaretPosition2.get(@props.sourceRef.current)

      if !@LastKeyDead
        text = event.target.innerText
        text = '' if text == "\n" # https://stackoverflow.com/a/72221694/1243212 (real new lines with ENTER are 2 chars "\r\n" I guess, so are correctly kept)
        @props.onChange(text)
      else
        @LastKeyDead = false

  render: ->
    React.createElement(
      @props.tagName || "div",
      {
        ref: @props.sourceRef,
        className: @props.className,
        dir: @props.direction,
        placeholder: @props.placeholder,
        contentEditable: !@props.disabled,
        autoFocus: @props.autoFocus,
        onKeyDown: @manageSpecialChars,
        onInput: @updateValue,
        dangerouslySetInnerHTML: { __html: @props.html }
      }
    )

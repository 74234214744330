export default class LimitWarning extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    # We receive snake-cased codes ('ip_monthly'), but we need them camelized ('ipMonthly') for localization
    warningCode = humps.camelize(@props.warningCode)
    userType    = if @props.currentUser then 'user' else 'visitor'

    text = @props.i18n[userType][warningCode].text
    text = text.replace('%{max_number}', @props.maxSourceSize) if @props.warningCode == 'char'
    cta  = @props.i18n[userType][warningCode].cta

    <div className="alert" role="alert">
      <p>
        <strong>
          {text}
        </strong>
      </p>

      <p>
        {cta}
      </p>

      { @renderButtons() }
    </div>

  renderButtons: ->
    if @props.currentUser
      <button className="btn btn-secondary btn-sm"
              onClick={@props.openModal.bind(this, 'limit')}>
        Yes, I need more
      </button>
    else
      <>
        <a className="btn btn-primary btn-sm sign-up"
           href="#{@props.paths.signUpPath}" >
          {@props.i18n.signUpButtonText}
        </a>

        <small dangerouslySetInnerHTML={{__html: @props.i18n.signInText}}>
        </small>
      </>

import ContentEditable from './ContentEditable'
import Segmenter       from './helpers/Segmenter'

export default class SourceA extends React.PureComponent
  constructor: (props) ->
    super(props)

    @onChange = @onChange.bind(this)

  componentDidMount: ->
    @props.sourceRef.current.focus({ preventScroll: true })

  onChange: (newSource) ->
    sentences = Segmenter.sentences(newSource, @props.languageCode)
    @props.onChange(sentences)

  formattedSource: ->
    @props.sources.map((sentence, index) =>
      words = Segmenter.words(sentence, @props.languageCode)
      words = words.map((word) =>
        if word.trim().length
          "<em>#{word}</em>"
        else
          word
      )

      sentence = words.join('')

      if sentence.trim().length
        classes = 'sentence'
        classes += ' active' if @props.activeSentenceIndex == index

        "<span class=\"#{classes}\">#{sentence}</span>"
      else
        sentence
    ).join('')

  render: ->
    <ContentEditable className={"textarea #{@props.fontSizeClass}"}
                     sourceRef={@props.sourceRef}
                     placeholder={@props.i18n.placeholder}
                     direction={@props.direction}
                     html={@formattedSource()}
                     onChange={@onChange}
                     disabled={false}
                     tagName='div' />

import onClickOutside from 'react-onclickoutside'

import TargetWord from './target_sentence/TargetWord'
import Segmenter  from './../helpers/Segmenter'

class TargetSentence extends React.PureComponent
  constructor: (props) ->
    super(props)

    @highlightSentence   = @highlightSentence.bind(this)
    @unhighlightSentence = @unhighlightSentence.bind(this)

  handleClickOutside: (e) ->
    # Important: ALL target sentences have this handler, so it is triggered as many times as there are target sentences
    # The ONLY case we want to handle is when one of the words in THIS current sentence is active (meaning that its alternative dropdown is open)
    # We know the alternative dropdown is open ONLY if (see the "active" prop we pass to TargetWord))
    # - the current sentence is active
    # - AND @props.activeWordIndex has a value (not -1)
    if @props.active && @props.activeWordIndex != -1
      @props.setActiveSentence(-1)
      @props.setActiveWord(-1)

  highlightSentence: ->
    # Highlight active sentence on mouseEnter only if no active word (and sentence already highlighted somewhere)
    if @props.activeWordIndex == -1
      @props.setActiveSentence(@props.index)

  unhighlightSentence: ->
    # Unhighlight active sentence on mouseLeave only if no active word (and sentence already highlighted somewhere)
    if @props.activeWordIndex == -1
      @props.setActiveSentence(-1) # no more active sentence

  render: ->
    words = Segmenter.words(@props.sentence, @props.languageCode)

    renderedWords = words.map((word, wordIndex) =>
      <TargetWord key="#{wordIndex}-#{word}"
                  word={word}
                  index={wordIndex}
                  sentenceIndex={@props.index}
                  active={@props.active && @props.activeWordIndex == wordIndex}
                  direction={@props.direction}
                  wordAlternatives={@props.wordAlternatives}
                  wordAlternativesLoading={@props.wordAlternativesLoading}
                  setActiveSentence={@props.setActiveSentence}
                  setActiveWord={@props.setActiveWord}
                  selectWordAlternative={@props.selectWordAlternative} />
    )

    classes = 'sentence'
    classes += ' active' if @props.active

    <span className={classes}
          onMouseEnter={@highlightSentence}
          onMouseLeave={@unhighlightSentence}>
      {renderedWords}
    </span>

export default onClickOutside(TargetSentence)

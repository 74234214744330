export default class CharacterCounter extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    limitReached = @props.textSize >= @props.maxTextSize

    classes = 'character-count'
    classes += ' limit-reached' if limitReached
      
    <div className={classes}>
      { @renderWarningIcon() if limitReached }
      { @renderCount() }
    </div>

  renderWarningIcon: ->
    <i className="fas fa-triangle-exclamation" />

  renderCount: ->
    <span>
      {@props.textSize}/{@props.maxTextSize}
    </span>

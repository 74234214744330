export default class ToneSelector extends React.PureComponent
  constructor: (props) ->
    super(props)

  updateTone: (tone) ->
    if tone != @props.tone
      @props.onChange(tone)

  render: ->
    if !@props.hidden
      <div className="tones d-none d-lg-flex">
        { @renderTones() }
      </div>
    else
      ''

  renderTones: ->
    @props.tones.map((tone) =>
      className = "btn btn-link tone tone-#{tone}"
      className += ' selected' if @props.tone == tone

      label = @props.i18n.labels[tone]

      <button key={tone}
              className={className}
              onClick={@updateTone.bind(this, tone)}>
        {label}
      </button>
    )

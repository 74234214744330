import LoadingDots from '../../LoadingDots'

export default class WordAlternatives extends React.PureComponent
  MINIMUM_MARGIN   = 22 # Avoid to stick modal to the borders of the screen - in px
  AESTHETIC_OFFSET = 3  # Always a bit before the word (aesthetic reasons) - in px

  constructor: (props) ->
    super(props)

    @state =
      offset: AESTHETIC_OFFSET

    @ref = React.createRef()

    @dUpdatePosition = _.debounce(@updatePosition.bind(this), 50)

  componentDidMount: ->
    addEventListener('resize', @dUpdatePosition) # Debounced to avoid to trigger each pixel when resizing

  componentWillUnmount: ->
    removeEventListener('resize', @dUpdatePosition)

  componentDidUpdate: ->
    @updatePosition()

  selectWordAlternative: (wordAlternative, e) ->
    e.stopPropagation() # don't propagate to `selectWord` and reopen the list of word alternatives

    @props.selectWordAlternative(wordAlternative)

  updatePosition: ->
    if @ref.current
      windowWidth       = window.innerWidth
      alternativesWidth = @ref.current.getBoundingClientRect().width

      # Distance between word and right/left side of the screen (depending on selected language)
      if @props.direction == 'rtl'
        wordOffset = windowWidth - @ref.current.parentElement.getBoundingClientRect().right
      else
        wordOffset = @ref.current.parentElement.getBoundingClientRect().left

      if alternativesWidth + 2 * MINIMUM_MARGIN > windowWidth               # Alternatives list is wider than window width
        offset = wordOffset - MINIMUM_MARGIN                                # => stick to left
      else if wordOffset + alternativesWidth + MINIMUM_MARGIN > windowWidth # Alternative list extends beyond side of the window
        rightOffset = windowWidth - alternativesWidth - wordOffset          # => stick to right
        offset = MINIMUM_MARGIN - rightOffset                               # /
      else                                                                  # Default behaviour
        offset = 0                                                          # => Stick to word

      @setState  (
        offset: AESTHETIC_OFFSET + offset
      )

  render: ->
    if @props.direction == 'rtl'
      styles = { right: -@state.offset }
    else
      styles = { left: -@state.offset }

    <ul ref={@ref} className="word-alternatives card" style={styles}>
      { @renderWordAlternativesLis() }
    </ul>

  renderWordAlternativesLis: ->
    if @props.loading
      <li className="words-loading">
        <LoadingDots visible={true} />
      </li>
    else
      @props.wordAlternatives.map((wordAlternative) =>
        liContent    = "#{wordAlternative}…"
        titleContent = if wordAlternative.length > 30 then liContent else '' # don't use title if word is small, only if it's long and has a chance to be cropped

        <li key={wordAlternative}
            onClick={@selectWordAlternative.bind(this, wordAlternative)}
            title={titleContent}>
          { liContent }
        </li>
      )

export default class Http
  @get: (url, request, successCallback, failCallback) ->
    uncamelizedRequest = humps.decamelizeKeys(request, {
      split: /(?=[A-Z0-9])/ # for humps v1 compatibility
    })

    # Custom attribute for offline tests
    if window.offlineTest
      failCallback()
    else
      req = $.get(url, uncamelizedRequest, (response) =>
        camelizedResponse = humps.camelizeKeys(response)

        successCallback(camelizedResponse) if(successCallback)
      )

      req.fail(failCallback) if(failCallback)

  @post: (url, request, successCallback, failCallback) ->
    uncamelizedRequest = humps.decamelizeKeys(request, {
      split: /(?=[A-Z0-9])/ # for humps v1 compatibility
    })

    # Custom attribute for offline tests
    if window.offlineTest
      failCallback()
    else
      req = $.post(url, uncamelizedRequest, (response) =>
        camelizedResponse = humps.camelizeKeys(response)

        successCallback(camelizedResponse) if(successCallback)
      )

      req.fail(failCallback) if(failCallback)

  @put: (url, request, successCallback, failCallback) ->
    @post(url, _.merge({ _method: 'PUT' }, request), successCallback, failCallback)

  @patch: (url, request, successCallback, failCallback) ->
    @post(url, _.merge({ _method: 'PATCH' }, request), successCallback, failCallback)

  @delete: (url, request, successCallback, failCallback) ->
    @post(url, _.merge({ _method: 'DELETE' }, request), successCallback, failCallback)

export default class Feedback extends React.PureComponent
  render: ->
    <div className="feedback">
      {@props.i18n.weWouldLove}
      &nbsp;
      <span className="love">
        <i className="fas fa-heart" aria-hidden="true"></i>
      </span>
      <br/>
      {@props.i18n.letUsKnow}
      &nbsp;
      <a target="_blank" href="mailto:contact@neurooo.com">contact@neurooo.com</a>
    </div>

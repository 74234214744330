import ExampleCard  from './marketing/ExampleCard'
import Testimonials from './marketing/Testimonials'

export default class Marketing extends React.PureComponent

  constructor: (props) ->
    super(props)

  render: ->
    <div id="marketing-section" className="container-fluid">
      <div className="container-lg">
        { @renderIntroduction() }
        { @renderFeatures() }
        <Testimonials testimonials={@props.i18n.testimonials}
                      assets={@props.assets} />
      </div>
    </div>

  renderIntroduction: ->
    i18n = @props.i18n.introduction

    <div className="introduction clearfix">
      <div className="section-title">
        <a href="#about-neuro">
          <i className="fas fa-angles-down fa-fade"></i>
        </a>
        { @renderIntroductionTitle() }
      </div>

      <div className="col-12 col-lg-6 col-xl-5 float-lg-end">
        <img srcSet="#{@props.assets.illustration} 1x, #{@props.assets.illustration2x} 2x" src={@props.assets.illustration}
             className="illustration"
             loading="lazy" />
      </div>

      <div className="description col-12 col-lg-5 col-xl-6">
        <p dangerouslySetInnerHTML={{ __html: i18n.p1 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p2 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p3 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p4 }} />
      </div>
    </div>

  renderIntroductionTitle: ->
    i18n = @props.i18n.introduction

    # We don't want Google to base its analysis on this H1
    # when the home page is loaded on another mode (e.g. files, apps),
    # so we render as a paragraph (styled as a h1) in those cases
    if @props.mode == 'text'
      <h1 id="about-neuro">
        {i18n.title}
        <span className="subtitle">
          {i18n.subtitle}
        </span>
      </h1>
    else
      <p id="about-neuro" className="h1">
        {i18n.title}
        <span className="subtitle">
          {i18n.subtitle}
        </span>
      </p>

  renderFeatures: ->
    <div className="features clearfix">
      <h2 className="subtitle">
        {@props.i18n.features.title}
      </h2>

      { @renderQualityFeature() }
      <hr />
      { @renderContextFeature() }
      <hr />
      { @renderToneFeature() }
      <hr />
      { @renderAdaptFeature() }
    </div>

  renderQualityFeature: ->
    i18n = @props.i18n.features.quality

    <div className="feature quality clearfix">
      <h3 className="col-12 col-lg-5 col-xxl-5">
        {i18n.title}
      </h3>

      <ExampleCard className="over-left col-12 col-lg-6 col-xxl-7 float-lg-start"
                   example={i18n.example}
                   i18n={@props.i18n.exampleCard} />

      <div className="description col-12 col-lg-5 col-xxl-5">
        <p dangerouslySetInnerHTML={{ __html: i18n.p1 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p2 }} />
      </div>
    </div>

  renderContextFeature: ->
    i18n = @props.i18n.features.context

    <div className="feature context clearfix">
      <h3 className="col-12 col-lg-4 col-xxl-5 float-lg-start">
        {i18n.title}
      </h3>

      <ExampleCard className="over-right col-12 col-lg-7 float-lg-end"
                   example={i18n.example}
                   i18n={@props.i18n.exampleCard} />

      <div className="description col-12 col-lg-4 col-xxl-5">
        <p dangerouslySetInnerHTML={{ __html: i18n.p1 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p2 }} />
      </div>
    </div>

  renderToneFeature: ->
    i18n = @props.i18n.features.tone

    <div className="feature tone clearfix">
      <h3 className="col-12 col-lg-4">
        {i18n.title}
      </h3>

      <ExampleCard className="over-left col-12 col-lg-7 col-xxl-8 float-lg-start"
                   example={i18n.example}
                   i18n={@props.i18n.exampleCard} />

      <div className="description col-12 col-lg-4">
        <p dangerouslySetInnerHTML={{ __html: i18n.p1 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p2 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p3 }} />
      </div>
    </div>

  renderAdaptFeature: ->
    i18n = @props.i18n.features.adapt

    <div className="feature context clearfix">
      <h3 className="col-12 col-lg-5 col-xxl-5 float-lg-start">
        {i18n.title}
      </h3>

      <div className="card over-right col-12 col-lg-6 col-xxl-7 float-lg-end">
        <img src={@props.assets.alternativeWords}
             className=""
             loading="lazy" />
        <p className="legend">
          {i18n.legend}
        </p>
      </div>

      <div className="description col-12 col-lg-5 col-xxl-5">
        <p dangerouslySetInnerHTML={{ __html: i18n.p1 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p2 }} />
        <p dangerouslySetInnerHTML={{ __html: i18n.p3 }} />
      </div>
    </div>

export default class NeuronIcon extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    <svg className="neuron" width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
      <g className="shape">
        <path className="vector-1" fillRule="evenodd" clipRule="evenodd" d="M9.60424 0C4.30003 0 0 4.33163 0 9.675V11.0187C0 16.362 4.30003 20.6937 9.60424 20.6937C14.9084 20.6937 19.2085 16.362 19.2085 11.0187V9.675C19.2085 4.33163 14.9084 0 9.60424 0ZM9.60424 4.03125C6.51007 4.03125 4.00177 6.55804 4.00177 9.675V11.0187C4.00177 14.1357 6.51007 16.6625 9.60424 16.6625C12.6984 16.6625 15.2067 14.1357 15.2067 11.0187V9.675C15.2067 6.55804 12.6984 4.03125 9.60424 4.03125Z"/>
        <path className="vector-2" d="M7.20227 11.2859C8.08632 11.2859 8.80298 10.4437 8.80298 9.40469C8.80298 8.3657 8.08632 7.52344 7.20227 7.52344C6.31822 7.52344 5.60156 8.3657 5.60156 9.40469C5.60156 10.4437 6.31822 11.2859 7.20227 11.2859Z"/>
        <path className="vector-3" d="M12.007 11.2859C12.891 11.2859 13.6077 10.4437 13.6077 9.40469C13.6077 8.3657 12.891 7.52344 12.007 7.52344C11.1229 7.52344 10.4062 8.3657 10.4062 9.40469C10.4062 10.4437 11.1229 11.2859 12.007 11.2859Z"/>
      </g>
    </svg>

export default class TargetCopyButton extends React.PureComponent
  
  constructor: (props) ->
    super(props)

    @state =
      popoverTextType: 'initial' # 'initial' or 'success'
      popoverVisible:  false

    @copyTargetToClipboard = @copyTargetToClipboard.bind(this)
    @showInitialPopover    = @showInitialPopover.bind(this)
    @hideInitialPopover    = @hideInitialPopover.bind(this)
    @hideSuccessPopover    = @hideSuccessPopover.bind(this)

  componentDidMount: ->
    window.TargetCopyButton = @

  copyTargetToClipboard: ->
    if @state.popoverTextType != 'success'
      navigator.clipboard.writeText(@props.targetText)

      @showSuccessPopover()

  buttonIsVisible: ->
    @props.targetText.length

  showInitialPopover: ->
    if @state.popoverTextType != 'success'
      @setState(
        popoverVisible:  true
        popoverTextType: 'initial'
      )

  hideInitialPopover: ->
    if @state.popoverTextType != 'success'
      @setState(popoverVisible: false)

  showSuccessPopover: ->
    @setState(
      popoverVisible:  true
      popoverTextType: 'success'
    )

    setTimeout(@hideSuccessPopover, 2000)

  hideSuccessPopover: ->
    @setState(popoverVisible: false, ->
      setTimeout((=>  @setState(popoverTextType: 'initial')), 300) # enough time for CSS transition
    )

  popoverText: ->
    if @state.popoverTextType == 'initial'
      @props.i18n.initialText
    else if @state.popoverTextType == 'success'
      <span>
        { @props.i18n.successText }
        &nbsp;
        <i className="fas fa-check"></i>
      </span>

  render: ->
    <div className="target-copy">
      { @renderButton() }
      { @renderPopover() }
    </div>

  renderButton: ->
    classes = "btn btn-link"
    classes += " d-none" if !@buttonIsVisible()

    <button className={classes}
            onClick={@copyTargetToClipboard}
            onMouseEnter={@showInitialPopover}
            onMouseLeave={@hideInitialPopover}>
      <i className="far fa-copy"></i>
    </button>

  renderPopover: ->
    classes = "neuro-popover"
    classes += " hidden" if !@state.popoverVisible

    <div className={classes}>
      { @popoverText() }
    </div>

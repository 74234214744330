import NeuronIcon from '../icons/NeuronIcon'

export default class LoadingDots extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    classes = "loading-dots"
    classes += " visible" if @props.visible

    <span className={classes}>
      <NeuronIcon />
      <NeuronIcon />
      <NeuronIcon />
    </span>

import Alternatives     from './target/Alternatives'
import EngineDropdown   from './target/EngineDropdown'
import LimitWarning     from './target/LimitWarning'
import LoadingDots      from './target/LoadingDots'
import TargetCopyButton from './target/TargetCopyButton'
import TargetSentence   from './target/TargetSentence'

import Segmenter from './helpers/Segmenter'

export default class Target extends React.PureComponent
  constructor: (props) ->
    super(props)

    @handleKeyboardEvent = @handleKeyboardEvent.bind(this)

  # Handles the focusing/unfocusing using the Tab key
  handleKeyboardEvent: (e) ->
    if e.target.id == 'target-area' && e.key == 'Tab'
      switch e.type
        # Keyup: the event ENDS in the target area, so we select the text
        when 'keyup'   then @props.selectTargetText()
        # Keydown: the event STARTS in the target area, so we unselect the text
        when 'keydown' then @props.unselectTargetText()

  render: ->
    # Not focusable if no translation is displayed
    tabIndex = if @props.targets.length then '0' else '-1'

    <div id="target-area"
         ref={@props.targetRef}
         tabIndex={tabIndex}
         onKeyDown={@handleKeyboardEvent}
         onKeyUp={@handleKeyboardEvent}>
      {@renderTargetContent()}
      {@renderAlternatives()}
      {@renderLimitWarning()}
      {@renderEnginesDropdown()}
      {@renderTargetCopyButton()}
    </div>

  renderTargetContent: ->
    renderedSentences = @props.targets.map((sentence, index) =>
      if sentence.trim().length
        <TargetSentence key="#{index}-#{sentence}"
                        sentence={sentence}
                        index={index}
                        active={@props.activeSentenceIndex == index}
                        activeWordIndex={@props.activeWordIndex}
                        languageCode={@props.languageCode}
                        direction={@props.direction}
                        wordAlternatives={@props.wordAlternatives}
                        wordAlternativesLoading={@props.wordAlternativesLoading}
                        setActiveSentence={@props.setActiveSentence}
                        setActiveWord={@props.setActiveWord}
                        selectWordAlternative={@props.selectWordAlternative} />
      else
        sentence
    )

    <div id="target-content"
         ref={@props.targetContentRef}
         dir={@props.direction}
         className={@props.fontSizeClass}>
      {renderedSentences}
      {@renderLoadingDots()}
    </div>

  renderLoadingDots: ->
    # Loading dots are always present, but only made visible when actually loading.
    # This is to avoid line jumps and height-resize jumps in the interface.
    <LoadingDots visible={@props.loading} />

  renderAlternatives: ->
    # Only keep alternatives that are not the current translation.
    # Should only happen in race conditions when target arrives before alternatives (unlikely!)
    alternatives = @props.alternatives.filter((alternativeTargets) =>
      !_.isEqual(@props.targets, alternativeTargets)
    )

    <Alternatives alternatives={alternatives}
                  selectAlternative={@props.selectAlternative}
                  direction={@props.direction}
                  i18n={@props.i18n} />

  renderLimitWarning: ->
    if @props.limitWarningCode != ''
      <LimitWarning warningCode={@props.limitWarningCode}
                    maxSourceSize={@props.maxSourceSize}
                    paths={@props.paths}
                    currentUser={@props.currentUser}
                    openModal={@props.openModal}
                    i18n={@props.i18n.limitWarning} />

  renderEnginesDropdown: ->
    <EngineDropdown engines={@props.engines}
                    authorizedEngines={@props.authorizedEngines}
                    engine={@props.engine}
                    selectEngine={@props.selectEngine}
                    openModal={@props.openModal}
                    i18n={@props.i18n.engineDropdown} />

  renderTargetCopyButton: ->
    <TargetCopyButton targetText={@props.targets.join('')}
                      i18n={@props.i18n.targetCopyButton} />

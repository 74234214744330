export default class Testimonials extends React.PureComponent
  constructor: (props) ->
    super(props)

  render: ->
    <div className="testimonials carousel-wrapper">
      <div id="testimonials-carousel" className="carousel carousel-dark slide w-100">
        <div className="carousel-inner">
          { @renderTestimonial(@props.testimonials.third,  @props.assets.testimonials3,'active') }
          { @renderTestimonial(@props.testimonials.first,  @props.assets.testimonials1) }
          { @renderTestimonial(@props.testimonials.second, @props.assets.testimonials2) }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#testimonials-carousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#testimonials-carousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>

  renderTestimonial: (testimonial, photo, extraClasses) ->
    <div className={"carousel-item h-100 #{extraClasses}"}>
      <figure className="testimonial-item-wrapper">
        <blockquote className="h3">
          &ldquo;
          <span dangerouslySetInnerHTML={{__html: testimonial.content }}></span>
          &rdquo;
        </blockquote>
        <figcaption>
          <img src={photo}/>
          <div>
            <p className="h6 fw-bold">{testimonial.name}</p>
            <p>{testimonial.role}, <a href={testimonial.website.url} target="_blank">{testimonial.website.name}</a></p>
          </div>
        </figcaption>
      </figure>
    </div>

export default class AppsPanel extends React.PureComponent

  render: ->
    <div className="col">
      <a href="https://translation.io" target="_blank" className="logo-wrapper">
        <img src={@props.assets.translationIo} alt={@props.i18n.logoAlt} />
      </a>

      <h2 dangerouslySetInnerHTML={{ __html: @props.i18n.title }} />
      <p dangerouslySetInnerHTML={{ __html: @props.i18n.p1 }} />
      <p dangerouslySetInnerHTML={{ __html: @props.i18n.p2 }} />

      <a href="https://translation.io" target="_blank" className="btn btn-primary">
        {@props.i18n.buttonLabel}
        &nbsp;
        <i className="fas fa-arrow-right" />
      </a>
    </div>
